import React from 'react';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const TelaEspera = () => {
    return (
        <div className='h-100 d-flex flex-column justify-content-center align-item-center'>
            <Alert color="secondary">
                Selecione um contato.
            </Alert>
            <FontAwesomeIcon icon={faUser} style={{ color: "#c2c2c2" }} size='10x' />
        </div>
    )
}

export default TelaEspera