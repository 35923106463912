import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import Header from 'app/shared/layout/header/header';
import ErrorBoundary from 'app/shared/error/error-boundary';
import AppRoutes from 'app/routes';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const authToken = sessionStorage.getItem("jhi-authenticationToken");
    dispatch(getSession(authToken));
  }, []);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  return (
    <BrowserRouter basename={baseHref}>
      <div className="app-container ps-2 pe-2" >
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
          {isAuthenticated && <Header
            isAuthenticated={isAuthenticated}
          />}
        </ErrorBoundary>

        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>

      </div>
    </BrowserRouter >
  );
};

export default App;
