import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { ContatoCadastrado } from "./components/ContatoCadastrado";
import { FormOportunidade } from "./components/FormOportunidade";
import { TelaEspera } from "./components/TelaEspera";

export type Contato = {
  id?: number;
  nome: string;
  celular: string;
  celularFormatado: string;
};

export const Home = () => {
  const emptyUser: Contato = {
    nome: "",
    celular: "",
    celularFormatado: "",
  };

  const storageToken = sessionStorage.getItem("jhi-authenticationToken");
  const authToken = JSON.parse(storageToken);

  const [numeroCliente, setNumeroCliente] = useState<string | null>(null);
  const [numeroContatoWppExiste, setNumeroContatoWppExiste] = useState(false);
  const [contato, setContato] = useState<Contato>(emptyUser);
  const [controlaMensagemCard, setControlaMensagemCard] = useState(false);

  //interval será utilizada na função clearInterval()
  let interval;

  //Sempre que alterar o numeroCliente, verifica se já existe um contato
  useEffect(() => {
    if (
      numeroCliente != null &&
      numeroCliente != "" &&
      numeroCliente.length >= 10 &&
      numeroCliente.length <= 11
    ) {
      verificaContato(numeroCliente);
    }

    //Iniciando setInterval: começando o envio de mensagens a cada 2 segundos
    startSetInterval();
  }, [numeroCliente]);

  function startSetInterval() {
    interval = setInterval(() => {
      window.parent.postMessage(
        { action: "getTelefoneCliente", numeroAtual: numeroCliente },
        "*"
      );
    }, 2000);
  }

  window.addEventListener("message", async function (event) {
    if (event.data.tipo === "telefone") {
      handleTelefoneEvent(event.data.data);
    }
  });

  const handleTelefoneEvent = (numero: string | null) => {
    //TODO: Verificar porquê as vezes retorna mais mensagens
    if (numeroCliente != numero) {
      setNumeroCliente(numero);

      //Limpando o setInterval: finaliza a execução do setInterval
      clearInterval(interval);
    }
  };

  const verificaContato = async (telefoneCliente: string) => {
    const usuarioVerificado = await checkPhoneNumberExists(telefoneCliente);

    if (usuarioVerificado.telefoneExiste) {
      await buscaDadosUsuarioCadastrado(usuarioVerificado.id);
      setControlaMensagemCard(true);
    }
  };

  const checkPhoneNumberExists = async (phoneNumber: string) => {
    try {
      const response = await axios.get(
        `/cliente/telefone/${phoneNumber.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setNumeroContatoWppExiste(response.data.telefoneExiste);
      return response.data;
    } catch (error) {
      console.error("Error checking phone number:", error);
      return false;
    }
  };

  const buscaDadosUsuarioCadastrado = async (idUsuario: number) => {
    try {
      const response = await axios.get(`/cliente/${idUsuario}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setContato(response.data);
    } catch (error) {
      console.error("Error checking phone number:", error);
    }
  };

  const handleOnSaveOpotunidade = (contatoOportunidade: Contato) => {
    setContato(contatoOportunidade);
    setNumeroContatoWppExiste(true);
    setControlaMensagemCard(false);
  };

  return (
    <Card className="h-100 w-100 p-1 m-0">
      {numeroCliente === null ? (
        <TelaEspera />
      ) : numeroContatoWppExiste ? (
        <ContatoCadastrado
          contato={contato}
          contatoJaCadastrado={controlaMensagemCard}
        />
      ) : (
        <FormOportunidade
          telefone={numeroCliente}
          onSaveOportunidade={handleOnSaveOpotunidade}
        />
      )}
    </Card>
  );
};

export default Home;
