import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import { ValidatedField } from 'react-jhipster';
import { Button, Alert, Row, Col, Form, Card } from 'reactstrap';
import { FieldError, useForm } from 'react-hook-form';
import "./login.scss";

export const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);


  const handleLogin = ({ username, password }) => dispatch(login(username, password));

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const doLogin = e => {
    handleSubmit(handleLogin)(e);
  };

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }


  return (
    <div className='loginContainer h-100 w-100'>
      <Card className='w-100 border-0 rounded pt-3 p-2 d-flex flex-column justify-content-center align-items-center' >
        <div className='logo_container'>
          <img src="content/images/Logotipo_Gradiente.png" alt="" className='logo' />
        </div >
        <h1 className='mt-5 text-center'>
          Bem vindo ao Ésses!
        </h1>
        <Form className="py-2 d-flex flex-column gap-3" onSubmit={doLogin}>
          <Row>
            <Col md="12">
              {loginError ? (
                <Alert color="danger" data-cy="loginError">
                  <strong>Erro ao fazer login.</strong> Usuário ou senha incorretos!
                </Alert>
              ) : null}
            </Col>
            <Col md="12">
              <ValidatedField
                name="username"
                label="Usuário"
                placeholder="Seu usuário"
                required
                autoFocus
                data-cy="username"
                validate={{ required: 'O campo usuário deve ser preenchido.' }}
                register={register}
                error={errors.username as FieldError}
                isTouched={touchedFields.username}
              />
              <ValidatedField
                name="password"
                type="password"
                label="Senha"
                placeholder="Sua senha"
                required
                data-cy="password"
                validate={{ required: 'O campo senha deve ser preenchido!' }}
                register={register}
                error={errors.password as FieldError}
                isTouched={touchedFields.password}
              />
            </Col>
          </Row>
          <Button color="primary" type="submit" data-cy="submit">
            Acessar
          </Button>
          <div className='d-flex flex-column align-items-center gap-2'>
            <Link to="https://crm.esses.app/" target='_blank'>
              Cadastre-se aqui
            </Link>
            <Link to="https://crm.esses.app/" target='_blank' >
              Recuperar senha
            </Link>
          </div>
        </Form >
      </Card >
    </div >
  );
};

export default Login;
