import axios from "axios";
import React from "react";
import InputMask from "react-input-mask";
import { ValidatedField, ValidatedForm } from "react-jhipster";
import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";
import type { Contato } from "../../home";

interface FormOportunidadeProps {
  telefone: string;
  onSaveOportunidade: (data: Contato) => void;
}

export const FormOportunidade = ({
  telefone,
  onSaveOportunidade,
}: FormOportunidadeProps) => {
  const storageToken = sessionStorage.getItem("jhi-authenticationToken");
  const authToken = JSON.parse(storageToken);

  const saveEntity = async (values) => {
    const body = {
      nome: values.nome,
      celular: telefone,
    };

    try {
      const response = await axios.post(`${SERVER_API_URL}/cliente`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      onSaveOportunidade(response.data);
    } catch (error) {
      toast.error("Erro ao tentar salvar oportunidade.");
    }
  };

  return (
    <ValidatedForm onSubmit={saveEntity} className="mt-4">
      <Label for="contato-celular">Celular</Label>
      <InputMask
        id="contato-celular"
        name="celular"
        type="text"
        mask="(99) 9999-9999"
        value={telefone}
        className="form-control"
        disabled
      />

      <ValidatedField label="Nome" id="contato-nome" name="nome" type="text" />
      <Button
        color="primary"
        className="w-100 rounded"
        id="save-entity"
        type="submit"
      >
        Adicionar oportunidade
      </Button>
    </ValidatedForm>
  );
};

export default FormOportunidade;
