import {
  faArrowUpRightFromSquare,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Contato } from "app/modules/home/home";
import React from "react";
import { Card, CardBody } from "reactstrap";

interface CardContatoProps {
  contato: Contato;
  contatoJaCadastrado: boolean;
}

export const CardContato = ({
  contato,
  contatoJaCadastrado,
}: CardContatoProps) => {
  return (
    <>
      <Card className="my-2 text-white bg-primary rounded">
        <CardBody>
          <p className="mb-1">
            <FontAwesomeIcon icon={faUser} /> {contato.nome}
            <a
              className="float-end"
              href={ESSES_FE_URL + "/funil/1"}
              target="_blank"
              rel="noopener noreferrer"
              placeholder="Visualizar no funil"
            >
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                color="#fff"
                beat
              />
            </a>
          </p>
          <p className="mb-0">
            <FontAwesomeIcon icon={faPhone} /> {contato.celularFormatado}
          </p>
        </CardBody>
      </Card>
    </>
  );
};

export default CardContato;
