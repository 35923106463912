import React from "react";
import { Navigate, PathRouteProps, useLocation } from "react-router-dom";

import { useAppSelector } from "app/config/store";
import ErrorBoundary from "app/shared/error/error-boundary";

interface IOwnProps extends PathRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children, ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated
  );
  const sessionHasBeenFetched = useAppSelector(
    (state) => state.authentication.sessionHasBeenFetched
  );
  const account = useAppSelector((state) => state.authentication.account);
  const hasPlan = account.hasEssencial;
  const pageLocation = useLocation();

  if (!children) {
    throw new Error(
      `A component needs to be specified for private route for path ${
        (rest as any).path
      }`
    );
  }

  if (!sessionHasBeenFetched) {
    return <div></div>;
  }

  if (isAuthenticated && !account.showLogin) {
    if (hasPlan) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          Consulte{" "}
          <a
            href="https://api.whatsapp.com/send/?phone=555198203924&text=Ol%C3%A1%2C%20fiquei%20interessado%20na%20integra%C3%A7%C3%A3o%20com%20Whatsapp.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
            target="_blank"
          >
            aqui
          </a>{" "}
          nossos planos para ter acesso à ferramenta de integração com Whatsapp.
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: "/login",
        search: pageLocation.search,
      }}
      replace
      state={{ from: pageLocation }}
    />
  );
};

/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
