import React from 'react';
import type { Contato } from '../../home';
import { CardContato } from './components/CardContato';
import { Anotacoes } from './components/Anotacoes';

interface ContatoCadastradoProps {
    contato: Contato
    contatoJaCadastrado: boolean
}

export const ContatoCadastrado = ({ contato, contatoJaCadastrado }: ContatoCadastradoProps) => {

    return (
        <>
            <CardContato
                contato={contato}
                contatoJaCadastrado={contatoJaCadastrado}
            />
            <hr />
            <Anotacoes idContato={contato.id} />
        </>
    )
}

export default ContatoCadastrado;