import './header.scss';

import React from 'react';

import { Navbar } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Brand } from './header-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IHeaderProps {
  isAuthenticated: boolean;
}

const Header = (props: IHeaderProps) => {

  const renderDevRibbon = () =>
    process.env.NODE_ENV !== 'production' ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" fixed="top" className="bg-primary d-flex flex-row justify-content-between p-2">
        <Brand />
        <Link to="/logout" data-cy="logout" className='text-white text-decoration-none logout_buttton'>
          <FontAwesomeIcon icon="sign-out-alt" color='white' fixedWidth />
          Sair
        </Link>
      </Navbar>
    </div >
  );
};

export default Header;
