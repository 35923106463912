import React from 'react';

import { NavbarBrand } from 'reactstrap';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/favicon-bp.png" alt="Logo" />
  </div>
);

export const Brand = () => (
  <NavbarBrand className="brand-logo">
    < BrandIcon />
    <span className="brand-title ">ÉSSES</span>
    {process.env.NODE_ENV !== 'production' && <span className="navbar-version">{VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`}</span>}
  </NavbarBrand >
);
