import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'app/config/store';
import axios, { AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';
import { serializeAxiosError } from './reducer.utils';
const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};
export type AuthenticationState = Readonly<typeof initialState>;

// Actions
export const getSession = (token?: string): AppThunk => (dispatch, getState) => {
  dispatch(getAccount(token));
};

export const getAccount = createAsyncThunk('authentication/get_account', async (token: string) =>
  axios.get<any>(`/users/me`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }), {
  serializeError: serializeAxiosError,
});

interface LoginCognitoResponse {
  data: {
    AuthenticationResult: {
      AccessToken: string;
      ExpiresIn: number;
      IdToken: string;
      RefreshToken: string;
      TokenType: string;
    }
  }
}

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (payload: any) => axios.post<any>('https://cognito-idp.sa-east-1.amazonaws.com', payload, {
    headers: {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1"
    }
  }),
  {
    serializeError: serializeAxiosError,
  },
);

export const login: (username: string, password: string) => AppThunk =
  (username, password) =>
    async dispatch => {
      const payload = {
        "AuthParameters": {
          "USERNAME": username,
          "PASSWORD": password
        }
        ,
        "AuthFlow": "USER_PASSWORD_AUTH",
        "ClientId": "214mjg6u5i49b84jdao63q1m60",
        "UserPoolId": "sa-east-1_yL6oyx94I"
      }
      const result = await dispatch(authenticate(payload));
      const response = result.payload as AxiosResponse;
      const bearerToken = response?.data?.AuthenticationResult.AccessToken;
      if (bearerToken) {
        var jwt = bearerToken;
        if (bearerToken.slice(0, 7) === 'Bearer ') {
          jwt = bearerToken.slice(7, bearerToken.length);
        }
        Storage.session.set(AUTH_TOKEN_KEY, jwt);
        dispatch(getSession(bearerToken));
      }
    };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => AppThunk = () => dispatch => {
  clearAuthToken();
  dispatch(logoutSession());
};

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    logoutSession() {
      return {
        ...initialState,
        showLogin: true,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showLogin: true,
        isAuthenticated: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => ({
        ...initialState,
        errorMessage: action.error.message,
        showLogin: true,
        loginError: true,
      }))
      .addCase(authenticate.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showLogin: false,
        loginSuccess: true,
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data ? true : false;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data.data,
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});
export const { logoutSession, authError, clearAuth } = AuthenticationSlice.actions;
// Reducer
export default AuthenticationSlice.reducer;