import {
  faCommentDots,
  faListCheck,
  faSquareCheck,
  faSquareMinus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Alert, Button, Container, Input } from "reactstrap";

type Anotacoes = {
  id: string;
  status: boolean;
  texto: string;
  imgData: string;
  ordem: number;
  imagemCarregada: boolean;
  albumInfo: {
    quantidadeFotos: number;
    ultimaFoto: boolean;
  }
};

type AnotacaoExtensao = {
  id: string;
  anotacao: string;
  imgData: string;
  ordem: number;
  imagemCarregada: boolean;
  albumInfo: {
    quantidadeFotos: number;
    ultimaFoto: boolean;
  }
};

interface AnotacoesProps {
  idContato: number;
}

export const Anotacoes = ({ idContato }: AnotacoesProps) => {
  const storageToken = sessionStorage.getItem("jhi-authenticationToken");
  const authToken = JSON.parse(storageToken);

  const [anotacoes, setAnotacoes] = useState<Anotacoes[] | null>(null);

  useEffect(() => {
    //Quando alterar o contato, limpa a lista de anotações.
    setAnotacoes(null);
  }, [idContato]);

  window.addEventListener("message", async function (event) {
    if (event.data.tipo === "mensagem") {
      handleMensagensEvent(event.data.data);
    }
  });

  const handleMensagensEvent = (mensagens: AnotacaoExtensao[] | null) => {
    if (mensagens === null) {
      setAnotacoes(null);
    } else if (anotacoes === null) {
      const novoArray: Anotacoes[] = mensagens.map((msg) => {
        return {
          id: msg.id,
          status: false,
          texto: msg.anotacao,
          imgData: msg.imgData,
          ordem: msg.ordem,
          imagemCarregada: msg.imagemCarregada,
          albumInfo: msg.albumInfo
        };
      });

      const arrayOrdenado = orderAnotacoesByOrdem(novoArray)

      setAnotacoes(arrayOrdenado);
    } else {
      //Quando já tem mensagens na lista e deseja carregar mais mensagens. Verifica e mantém status das mensagens já carregadas anteriormente
      const novoArray: Anotacoes[] = mensagens.map((msg) => {
        const mensagemJaCarregada = verificaMensagemJaCarregada(msg.id);

        if (mensagemJaCarregada) {
          //retorna atualizando ordem da mensagems
          return { ...mensagemJaCarregada, ordem: msg.ordem };
        } else {
          return {
            id: msg.id,
            status: false,
            texto: msg.anotacao,
            imgData: msg.imgData,
            ordem: msg.ordem,
            imagemCarregada: msg.imagemCarregada,
            albumInfo: msg.albumInfo
          };
        }
      });

      const arrayOrdenado = orderAnotacoesByOrdem(novoArray)

      setAnotacoes(arrayOrdenado);
    }
  };

  const orderAnotacoesByOrdem = (list: Anotacoes[]) => {
    const newList = list.sort(function (a, b) {
      return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
    });
    return newList;
  }

  const verificaMensagemJaCarregada = (idMensagem: string) => {
    return anotacoes.find((anotacao) => anotacao.id === idMensagem);
  };

  const getMessages = () => {
    window.parent.postMessage({ action: "getMensagens" }, "*");
  };

  const changeMessageStatus = (anotacaoId: string, statusCheck: boolean) => {
    const index = anotacoes.findIndex((anotacao) => anotacao.id === anotacaoId);
    const updatedListaAnotacoes = [...anotacoes];
    updatedListaAnotacoes[index].status = statusCheck;
    setAnotacoes(updatedListaAnotacoes);
  };

  const changeAllMessagesStatus = (newStatus: boolean) => {
    const newList = anotacoes.map((anotacao) => {
      return { ...anotacao, status: newStatus };
    });

    setAnotacoes(newList);
  };

  const saveMessages = async () => {
    var selectedMessages = "";
    var anexos = []
    var numImg = 0;
    anotacoes.map((anotacao) => {
      if (anotacao.status) {

        // Se tiver texto, adiciona \n antes da imagem
        selectedMessages += (anotacao.texto === "") ? "" : anotacao.texto + `
    `;

        if (anotacao.imgData) {
          numImg++;

          //Código para exibir a imagem nas anotações
          // selectedMessages += `#imagem_${numImg}` + `
          //     `;

          //removendo prefixo 'data:image/jpeg;base64,' da string base64
          const [, base64Data] = anotacao.imgData.split(",");

          anexos.push({
            nome: `imagem${numImg}`,
            arquivoBase64: base64Data,
            formato: 1,
            ordem: numImg
          })

        }
      }

    });

    if (selectedMessages === "") {
      toast.info("Selecione as mensagens primeiro");
    } else {
      const body = {
        descricao: selectedMessages,
        idContato: idContato,
        anexos: anexos
      };

      await axios.post(`${SERVER_API_URL}/anotacoes`, body, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          toast.success("Sucesso! Suas anotações foram salvas.");
        })
        .catch((err) => {
          toast.error("Erro ao tentar salvar anotações");
        });
    }
  };

  const showClearButton = anotacoes?.some(
    (anotacao) => anotacao.status === true
  );

  const allSelected = anotacoes?.every((anotacao) => anotacao.status === true);

  return (
    <>
      {anotacoes === null ? (
        <Button
          className="mt-2 rounded w-100"
          color="primary"
          onClick={() => getMessages()}
        >
          <FontAwesomeIcon icon={faListCheck} /> Selecionar anotações
        </Button>
      ) : (
        <Container fluid className="mt-2 p-0">
          <div className="d-flex flex-row justify-content-between m-0 py-1">
            <div className="d-flex flex-row gap-2 m-0 p-0">
              {allSelected ? (
                <FontAwesomeIcon
                  onClick={() => changeAllMessagesStatus(false)}
                  icon={faSquareMinus}
                  color="#593196"
                  size="lg"
                />
              ) : (
                <Input
                  type="checkbox"
                  className="focus-ring focus-ring-light py-1 border border-primary"
                  style={{ borderRadius: "3px" }}
                  onChange={(el) => changeAllMessagesStatus(el.target.checked)}
                />
              )}

              {showClearButton && !allSelected && (
                <Button
                  className="rounded border border-primary px-1 py-0"
                  onClick={() => changeAllMessagesStatus(false)}
                >
                  <FontAwesomeIcon
                    icon={faSquareMinus}
                    color="#593196"
                    size="1x"
                  />
                  &nbsp; Limpar
                </Button>
              )}
            </div>

            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setAnotacoes(null)}
            />
          </div>

          <div
            style={{
              overflow: "auto",
              maxHeight: "50vh",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <div>
              {anotacoes != null &&
                anotacoes.map((anotacao) => (
                  <div
                    key={anotacao.id}
                    className="d-block my-3"
                  >
                    <div className="d-flex flex-row gap-1 justify-content-start">
                      <Input
                        type="checkbox"
                        checked={anotacao.status}
                        className="border border-primary focus-ring focus-ring-light"
                        onChange={(el) =>
                          changeMessageStatus(anotacao.id, el.target.checked)
                        }
                        style={{ borderRadius: "3px" }}
                      />
                      <p className="text-break mb-1">{anotacao.texto}</p>
                    </div>
                    {!anotacao.imagemCarregada && (<p className="text-warning ms-4 ">Identificamos uma imagem não carregada.</p>)}
                    {anotacao.imgData && <img src={anotacao.imgData} style={{ maxWidth: '100px' }} className="img-fluid float-left ms-4 mb-2"></img>}
                    {anotacao.albumInfo.ultimaFoto && (
                      <Alert color="secondary" className="m-3" >
                        Identificamos um grupo contendo {anotacao.albumInfo.quantidadeFotos} imagens. Algumas imagens podem não ter sido carregadas e terão que ser anexadas manualmente.
                      </Alert>
                    )}

                  </div>
                ))}
            </div>
          </div>
          <Button
            className="mt-3 rounded w-100"
            color="primary"
            onClick={() => saveMessages().then(() => setAnotacoes(null))}
          >
            <FontAwesomeIcon icon={faSquareCheck} /> Salvar anotações
          </Button>
          <Button
            className=" mt-1 rounded w-100"
            color="primary"
            onClick={() => getMessages()}
          >
            <FontAwesomeIcon icon={faCommentDots} /> Carregar mais anotações
          </Button>
        </Container>
      )}
    </>
  );
};

export default Anotacoes;
